.slick-track {
  margin-left: 0 !important;
}

.nft_coll {
  background-color: #2e3338;
  border: none;
}

.greyscheme .nft_coll h4 {
  color: white;
  font-weight: 500;
}

.collection-social-icon-parent .social>a {
  padding: 15px;
  cursor: pointer;
}

.collection-social-icon-parent .social>a:hover {
  transition: all ease-in-out 300ms;
  transform: scale(1.3);
}

.share-dd-item:hover {
  background: none;
  color: #ccc !important;
}

.collection-page-stats h4, .collection-page-stats p {
  margin-bottom: 0;
}

.collectionTabs .nav-item:first-child a {
  padding-left: 0;
}

.collectionTabs .nav-item a:hover {
  color: #888;
}

.borderColor {
  border-color: #353c42 !important;
}

.list-style-none {
  list-style: none;
}

.btn-custom-card {
  border: none;
  background: none;
  color: #FF343F;
  display: block;
}

.collectionTabs .nav-item a.active {
  color: #FF343F;
}

.categoryTabs .nav-item a.active {
  border-bottom: 1px solid #fff;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: #000 !important;
}